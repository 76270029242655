<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Alterar password</span>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <label class="label-modal">{{ $t("fields.pwd") }}</label>
                <v-text-field
                  :color="$primary"
                  :rules="[RULES.required]"
                  type="password"
                  v-model="pwd"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <label class="label-modal">{{ $t("fields.c_pwd") }}</label>
                <v-text-field
                  :color="$primary"
                  type="password"
                  v-model="cpwd"
                  :rules="[RULES.required, RULES.c_pwd]"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$primary"
            class="white--text"
            txt
            @click.native="dialog = false"
            >{{ $t("actions.cancel") }}</v-btn
          >
          <v-btn
            :color="$primary"
            class="white--text"
            txt
            @click.native="changeData"
            >{{ $t("actions.confirm") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import RULES from "@/mixins/_RULES"
export default {
  mixins: [RULES],
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    }
  },
  data: () => ({
    dialog: false,
    pwd: "",
    cpwd: "",
    defaultRules: null,
    pwdRules: null
  }),
  watch: {
    dialog(val) {
      val || this.close()

      this.$forceUpdate()
    }
  },
  mounted() {},
  created() {
    this.$eventBus.$on("ChangePwdDialog", state => {
      this.dialog = state
    })
  },
  methods: {
    changeData() {
      if (this.pwd && this.pwd == this.cpwd)
        this.$store
          .dispatch("session/update", {
            id: this.getSession.user.id_utilizador,
            password: this.pwd
          })
          .then(res => {
            if (res) {
              this.$toast.success($t("messages.success"))
              this.dialog = false
              setTimeout(() => {
                this.pwd = this.cpwd = ""
              }, 110)
            } else {
              this.$toast.error(this.$t("messages.error"))
              this.dialog = false
              setTimeout(() => {
                this.pwd = this.cpwd = ""
              }, 110)
            }
          })
    },
    close() {
      this.dialog = false

      setTimeout(() => {
        this.pwd = this.cpwd = ""
      }, 110)
    }
  }
}
</script>
<style scoped>
.apagar {
  line-height: 30px !important;
  font-size: 17px !important;
}
</style>
